class HeaderSlides {

    constructor({urlPath, imageServerPathPrefix}, {interval, duration, contentDelay, contentDuration}, breakPoints, containerHeight, slides) {
        const baseUrl = new URL(imageServerPathPrefix.endsWith('/') ? imageServerPathPrefix : `${imageServerPathPrefix}/`, (new URL(urlPath, `${window.location.protocol}//${window.location.hostname}`).href))

        let slideIndex = 0
        let slideIntervalId = null
        let slideDelayId = null
        let slideTimeoutId = null

        const getViewportWidth = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const getNextBreakPoint = width => Object.entries(breakPoints).reduce((breakPoint, [key, maxWidth]) => breakPoint || (maxWidth > width ? key : null), null)
        const getImageUrl = imagePath => new URL(`${imagePath}-${breakPoints[getNextBreakPoint(getViewportWidth())]}w.${containerHeight}h.webp`, baseUrl).href
        const setSlide = (slide, index) => {
            const slideIndex = index % slides.length
            slide.find('.slidesSlideBg').attr('class', 'slidesSlideBg slide-' + slides[slideIndex].contentId)

            const slideContent = $('.slidesOverlay.template').clone().removeClass('template')
            slideContent.find('.slidesOverlayCenter').html($('.slidesOverlayContents .slidesOverlayContent-' + slides[slideIndex].contentId)[0].outerHTML)
            slide.find('.slidesSlideContent').empty().append(slideContent)
        }
        const setCurrentSlide = index => setSlide($('.slidesSlide-0'), index)
        const setNextSlide = index => setSlide($('.slidesSlide-1'), index)
        const setSlides = index => {
            setCurrentSlide(index)
            setNextSlide(index + 1)
        }
        const setSlideController = index => {
            $('.slidesController .slidesSlideController').removeClass('active')
            $('.slidesController .slidesSlideController-' + (index % slides.length)).addClass('active')
        }
        const disableSlideController = () => $('.slidesController button').attr('disabled', 'disabled')
        const enableSlideController = () => $('.slidesController button').removeAttr('disabled')
        const showSlideContent = () => {
            if (slideTimeoutId) {
                clearTimeout(slideTimeoutId)
            }
            slideTimeoutId = setTimeout(() => $('.slides .slidesSlide-0 .slidesOverlay').css('right', 0), contentDelay * 1000)
        }
        const showSlide = index => {
            disableSlideController()
            setSlideController(index)
            setNextSlide(index)
            $('.slides').addClass('animated').css('left', '-100%')

            if (slideTimeoutId) {
                clearTimeout(slideTimeoutId)
            }
            slideTimeoutId = setTimeout(() => {
                setCurrentSlide(index)
                slideTimeoutId = setTimeout(() => {
                    $('.slides').removeClass('animated').css('left', 0)
                    showSlideContent()
                }, 100)
                enableSlideController()
            }, duration * 1000 + 100)
        }
        const nextSlide = () => {
            showSlide(++slideIndex)
            preloadSlide(slideIndex + 1)
        }
        const initController = index => {
            const controller = $('.slidesController ul')
            const template = controller.find('li.template').removeClass('template').remove()
            controller.append($.map(slides, (slide, i) => {
                const button = template.clone().addClass('slidesSlideController-' + i)
                button.find('button').click(() => {
                    clearSlideInterval()
                    if (slideIndex !== i) {
                        showSlide(slideIndex = i)
                    }
                    if (slideDelayId) {
                        clearTimeout(slideDelayId)
                    }
                    slideDelayId = setTimeout(startSlideInterval, interval * 1000)
                })
                button.find('.slidesSlideIndex').html('#' + (index + 1))
                return button
            }))
            controller.addClass('ready')
            setSlideController(index)
        }
        const clearSlideInterval = () => {
            if (slideIntervalId) {
                clearInterval(slideIntervalId)
                slideIntervalId = null
            }
        }
        const startSlideInterval = () => {
            clearSlideInterval()
            slideIntervalId = setInterval(nextSlide, interval * 1000)
        }
        const preloadSlide = index => setTimeout(() => $('.slidesPreloader').css('background', 'url(' + getImageUrl(slides[index % slides.length].url) + ')'), 300)

        initController(slideIndex)
        setSlides(slideIndex)
        showSlideContent()
        preloadSlide(slideIndex + 1)
        slideIntervalId = setTimeout(() => {
            nextSlide()
            startSlideInterval()
        }, (interval - duration) * 1000)
    }
}